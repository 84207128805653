<template>
  <v-container>
    <!--header-->
    <v-row class="mb-10">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card-title class="mb-5">
          {{ getCampanha.nome_campanha }}
        </v-card-title>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3">
        <BaseCard
          informationPrimary="Custo Estimado"
          :valuePrimary="getCampanha.valor_custo | BrazilianCurrency"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3">
        <BaseCard
          informationPrimary="Crescimento"
          :valuePrimary="getCampanha.perc_crescimento + '%'"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3">
        <BaseCard
          informationPrimary="Fechamento"
          :valuePrimary="getCampanha.tipo_dado"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3">
        <BaseCard
          informationPrimary="Início"
          informationSecondary="Fim"
          :valuePrimary="getCampanha.data_inicio | BrazilianStandardDate"
          :valueSecondary="getCampanha.data_final | BrazilianStandardDate"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <div class="objetivo">
          <v-card-subtitle>
            <h3>Objetivo</h3>
            {{ getCampanha.objetivo_campanha }}
          </v-card-subtitle>
        </div>
      </v-col>
    </v-row>
    <!--Fim header-->

    <!--Empresas-->
    <div class="mb-10">
      <v-data-table
        :headers="empresas.headers"
        :items="getEmpresasParticipantes"
        sort-by="data_final"
        class="elevation-1"
        :loading="getLoadingState"
        loading-text="Carregando... Aguarde"
        style="white-space: nowrap"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Empresas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <!-- Descrição -->
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome | TitleCase }}
        </template>

        <template v-slot:[`item.indicador`]="{ item }">
          {{ item.id_indicador }} - {{ item.indicador }}
        </template>
        <!-- Seguimento -->
        <template v-slot:[`item.id_band`]="{ item }">
          <BaseSegmento :segmento="item.id_band" />
        </template>
      </v-data-table>
    </div>
    <!--Fim empresas-->

    <SectionPublicoMeta :onEdite="false" />
    <v-btn
      @click="confirmaCadastroCampanha()"
      dark
      absolute
      bottom
      right
      v-if="onEdite"
      color="success"
    >
      <v-icon>mdi mdi-check</v-icon>
      Confirmar Cadastro
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import campanhaService from "@/services/campanha";
import dataMixins from "@/mixins/dateMixin";
import currencyMixin from "@/mixins/currencyMixin";
import BaseCard from "@/components/shared/BaseCardDashboard";

export default {
  components: {
    BaseCard,
    SectionPublicoMeta: () => import("../PublicoMeta/SectionPublicoMeta"),
    BaseSegmento: () => import("@/components/shared/BaseSegmento")
  },
  mixins: [dataMixins, currencyMixin],
  props: {
    onEdite: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      expanded: [],
      tipoFechamento: [],
      dataInicio: "",
      dataTermino: "",
      empresas: {
        headers: [
          { text: "Bandeira", value: "id_band" },
          { text: "Apelido", value: "apelido" },
          { text: "Empresa", value: "nome" }
        ]
      },

      publicoAlvo: {
        headers: [
          { text: "Código da Função", value: "cod_funcao" },
          { text: "Descrição", value: "descricao" },
          { text: "Seguimento", value: "id_band" }
        ]
      },
      metasCadastradas: {
        headers: [
          { text: "Função", value: "funcao" },
          { text: "Indicador", value: "indicador" },
          { text: "Tipo de Meta", value: "tipo_meta" },
          { text: "Base de Cálculo", value: "tipo_calculo" },
          { text: "Crescimento", value: "perc_cresc" },
          { text: "Valor Bônus", value: "valor_bonus", align: "right" },
          { text: "Prioridade do Indicador", value: "maior_menor" }
        ]
      },
      metasProgressivas: {
        headers: [
          { text: "Função", value: "funcao" },
          { text: "Indicador", value: "indicador" },
          { text: "Base de Cálculo", value: "tipo_calculo" },
          { text: "Prioridade do Indicador", value: "maior_menor" },
          { text: "", value: "data-table-expand" }
        ]
      }
    };
  },

  computed: {
    ...mapGetters({
      getLoadingState: "campanha/getLoadingState",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes",
      getCampanha: "campanha/getCampanha"
    })
  },

  methods: {
    ...mapActions({
      actionCampanha: "campanha/actionCampanha",
      actionEmpresasParticipantes: "campanha/actionEmpresasParticipantes"
    }),
    async confirmaCadastroCampanha() {
      try {
        await this.$root.dialog({
          type: "info",
          title: "Confirmação de Cadastro",
          text:
            "Ao confirmar o cadastro a campanha ficará disponível para aprovação. Ao ser aprovada não será possivel sua edição!",
          confirmText: "Sim",
          rejectText: "Não"
        });

        await campanhaService.putCampanha(this.$route.params.idCampanha, {
          status: "S"
        });

        this.$router.push("/campanhas/consulta");

        this.$notify({
          type: "success",
          text: "Campanha cadastrada com sucesso"
        });
      } catch (error) {
        if (error.response) {
          this.$notify({
            type: "error",
            text: "Não foi possível confirmar cadastro da campanha!"
          });
        }
      }
    }
  },
  mounted() {
    this.actionCampanha(this.$route.params.idCampanha);
    this.actionEmpresasParticipantes(this.$route.params.idCampanha);
  }
};
</script>

<style scoped>
.card-text {
  display: flex;
}

.objetivo {
  background-color: #e9e9e9;
}
</style>
