<template>
  <v-card class="d-flex align-center justify-space-between">
    <v-divider vertical class="divider-card"></v-divider>
    <div>
      <v-card-title class="card-title1">{{ informationPrimary }}</v-card-title>
      <v-card-subtitle>{{ subInformationPrimary }}</v-card-subtitle>
      <v-card-title class="card-title2">{{
        informationSecondary
      }}</v-card-title>
      <v-card-subtitle>{{ subInformationSecondary }}</v-card-subtitle>
    </div>

    <div class="d-flex flex-column align-end">
      <v-card-title class="card-title1"
        ><v-icon medium :color="colorPrimary" class="mr-1">{{
          iconPrimary
        }}</v-icon
        >{{ valuePrimary }}</v-card-title
      >
      <v-card-title class="card-title2"
        ><v-icon small :color="colorSecondary" class="mr-1">{{
          iconSecondary
        }}</v-icon
        >{{ valueSecondary }}</v-card-title
      >
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    informationPrimary: {
      type: String,
      default: ""
    },
    informationSecondary: {
      type: String,
      default: ""
    },
    subInformationPrimary: {
      type: String,
      default: ""
    },
    subInformationSecondary: {
      type: String,
      default: ""
    },
    valuePrimary: {
      type: [Number, String],
      default: ""
    },
    valueSecondary: {
      type: [Number, String],
      default: ""
    },
    iconPrimary: {
      type: String,
      default: ""
    },
    iconSecondary: {
      type: String,
      default: ""
    },
    colorPrimary: {
      type: String,
      default: ""
    },
    colorSecondary: {
      type: String,
      default: ""
    }
  },

  watch: {
    informationPrimary() {
      return this.informationPrimary;
    },
    informationSecondary() {
      return this.informationSecondary;
    },
    subInformationPrimary() {
      return this.subInformationPrimary;
    },
    subInformationSecondary() {
      return this.subInformationSecondary;
    },
    valuePrimary() {
      return this.valuePrimary;
    },
    valueSecondary() {
      return this.valueSecondary;
    },
    iconPrimary() {
      return this.iconPrimary;
    },
    iconSecondary() {
      return this.iconSecondary;
    },
    colorPrimary() {
      return this.colorPrimary;
    },
    colorSecondary() {
      return this.colorSecondary;
    }
  }
};
</script>

<style scoped>
.v-card {
  height: 100%;
}

.card-title1 {
  font-size: 1.25rem;
  padding: 5px 16px;
}

.card-title2 {
  font-size: 1rem;
  padding: 5px 16px;
}

.divider-card {
  left: 55%;
  min-height: 80% !important;
  top: 10%;
  max-height: 80% !important;
  position: absolute;
}
</style>
